import { FormattedMessage, useIntl } from 'react-intl';

import CollectionLink from 'components/ui/CollectionLink';
import DetailBlock from 'components/ui/DetailBlock';
import DetailInline from 'components/ui/DetailInline';
import Hr from 'components/ui/Hr';
import { CustomField } from 'components/upload/CustomFields';
import FileTypeIcon from 'components/upload/FileTypeIcon';
import { DamFile } from 'types';
import { handleError } from 'utils/errors';
import { displayDate, displayDateTime, displayFileSize, displayNumber } from 'utils/formatting';
import { bufferToJson, devLog, fileIsTimedTransfer } from 'utils/helpers';
import { validateCustomFields } from 'utils/validation';

import {
  accessPermissionFee,
  actions,
  checksum,
  createdAt,
  cryptography,
  customFieldsText,
  details,
  expiration,
  hash,
  identifier,
  lastModified,
  name,
  size,
  timedTransfer,
  transferFee,
  type,
} from 'intl/generic';
import FileActions from './FileActions';
import UpdateFile from './UpdateFile';

type Props = {
  file: DamFile;
  isOwner: boolean;
  isAllowed: boolean;
};

const FileCard = ({ file, isOwner, isAllowed }: Props) => {
  const { formatMessage, locale } = useIntl();

  const getCustomFields = (input: Buffer) => {
    let fields: CustomField[] = [];

    const data = bufferToJson(input);

    try {
      fields = validateCustomFields(data);
    } catch (err) {
      devLog(err);
      handleError('Could not process custom fields');
    }

    return fields;
  };

  const isTimed = fileIsTimedTransfer(file);
  const customFields = getCustomFields(file.data.customFields);

  return (
    <div className="flex justify-center text-sm">
      <div className="shadow-md rounded-lg bg-white p-8 border-2 border-gray-50 w-full">
        <div className="flex justify-end h-4">{isOwner && !isTimed && <UpdateFile file={file} />}</div>

        <div className="flex justify-center gap-4 items-center mt-4">
          <h1 className="text-secondary-200">
            <FileTypeIcon file={file} />
          </h1>

          <h3 className="block">{file.data.title}</h3>
        </div>

        {file.meta.collection.title && (
          <p className="text-center text-xs text-gray-400 font-semibold">
            <FormattedMessage id="view.partOf" defaultMessage="Part of" description="Part of collection text" />:{' '}
            <CollectionLink file={file} type="text" />
          </p>
        )}

        <Hr text={formatMessage(details)} className="mt-8" />

        <DetailInline label={formatMessage(name)} value={file.data.name} />
        <DetailInline label={formatMessage(size)} value={displayFileSize(file.data.size)} />
        <DetailInline label={formatMessage(type)} value={file.data.type || 'unknown'} />

        <div className="h-4" />

        <DetailInline label={formatMessage(createdAt)} value={displayDateTime(file.meta.createdAt.human, locale)} />
        <DetailInline
          label={formatMessage(lastModified)}
          value={displayDateTime(file.meta.lastModified.human, locale)}
        />

        <div className="h-4" />

        <DetailInline label={formatMessage(transferFee)} value={`${displayNumber(file.data.transferFee)} tokens`} />
        <DetailInline
          label={formatMessage(accessPermissionFee)}
          value={isTimed ? 'N/A' : `${displayNumber(file.data.accessPermissionFee)} tokens`}
        />
        <DetailInline label={formatMessage(timedTransfer)} value={isTimed ? 'Yes' : 'No'} />
        <DetailInline
          label={formatMessage(expiration)}
          value={isTimed ? displayDate(file.meta.expiration.human, locale) : 'N/A'}
        />

        <Hr text={formatMessage(cryptography)} className="mt-12" />

        <DetailBlock label={formatMessage(identifier)} value={file.data.id} />
        <DetailBlock label={formatMessage(checksum)} value={file.data.checksum} />
        <DetailBlock label={formatMessage(hash)} value={file.data.hash} />

        {customFields.length > 0 && (
          <>
            <Hr text={formatMessage(customFieldsText)} className="mt-12" />
            {customFields.map((f, i) => (
              <DetailInline key={`${f.name}-${i}`} label={f.name} value={f.value} />
            ))}
          </>
        )}

        <Hr text={formatMessage(actions)} className="mt-12" />

        <FileActions isTransferrable={isOwner && !isTimed} file={file} isOwner={isOwner} isAllowed={isAllowed} />
      </div>
    </div>
  );
};

export default FileCard;
