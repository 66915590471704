import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const LabelDescription = ({ children }: Props) => {
  return <div className="text-xs text-gray-300">{children}</div>;
};

export default LabelDescription;
