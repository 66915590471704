import { ReactElement } from 'react';

const DetailInline = ({ label, value }: { label: string | ReactElement; value: any }) => (
  <div className="flex justify-between">
    <div>{label}</div>
    <div className="text-right font-bold">{value}</div>
  </div>
);

export default DetailInline;
