import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from 'components/ui/Button';
import SEO from 'components/ui/SEO';
import { notFound, goBackMessage } from 'intl/generic';

const NotFoundPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex justify-center items-center h-full">
      <SEO pageTitle="404" pageDescription={`404 - ${formatMessage(notFound)}`} />

      <div className="text-center space-y-4">
        <h1>
          404 - <FormattedMessage {...notFound} />
        </h1>
        <p className="mb-4">
          <FormattedMessage
            id="error.404.description"
            defaultMessage="Whoops! This page does not exist."
            description="Error description"
          />
        </p>

        <Button onClick={goBack}>
          <FormattedMessage {...goBackMessage} />
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
