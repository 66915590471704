import { useAuth0 } from '@auth0/auth0-react';

import Button from 'components/ui/Button';
import { FormattedMessage } from 'react-intl';

const LogInButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleLogIn = () => {
    loginWithRedirect();
  };

  const handleLogOut = () => {
    logout({ returnTo: `${window.location.origin}?logout=true` });
  };

  if (isAuthenticated) {
    return (
      <Button color="primary" onClick={handleLogOut}>
        <FormattedMessage id="navbar.logout" description="Navbar menu item" defaultMessage="Log Out" />
      </Button>
    );
  }

  return (
    <Button color="primary" onClick={handleLogIn}>
      <FormattedMessage id="navbar.login" description="Navbar menu item" defaultMessage="Log In" />
    </Button>
  );
};

export default LogInButton;
