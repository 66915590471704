import { Locale } from 'intl/Intl';
import { getFromLocalStorage, setToLocalStorage } from 'utils/storage';
import { create } from 'zustand';

type CustomizationStoreState = {
  locale: Locale;
  updateLocale: (value: Locale) => void;
};

const defaultBrowserLanguage = navigator.language;

const getInitialLocale = (): Locale => {
  const savedLocale = getFromLocalStorage<Locale>('locale');

  return savedLocale ?? (defaultBrowserLanguage as Locale) ?? 'en';
};

const sanitizeLocaleInput = (input: Locale): Locale => {
  const fullLocaleRegex = /^[a-z]{2}-[A-Z]{2}$/;

  if (input.match(fullLocaleRegex)) {
    return input.slice(0, 2) as Locale;
  }

  const localeRegex = /^[a-z]{2}$/;

  if (!input.match(localeRegex)) {
    throw new Error('Invalid locale');
  }

  return input;
};

export const useCustomizationStore = create<CustomizationStoreState>(set => ({
  locale: sanitizeLocaleInput(getInitialLocale()),
  updateLocale: input => {
    const locale = sanitizeLocaleInput(input);
    setToLocalStorage(locale, 'locale');
    set({ locale });
  },
}));
