import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from 'components/ui/Button';
import SEO from 'components/ui/SEO';
import { forbidden } from 'intl/generic';

const ForbiddenPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-full">
      <SEO pageTitle="403" pageDescription={`403 - ${formatMessage(forbidden)}`} />

      <div className="text-center space-y-4">
        <h1>
          403 - <FormattedMessage {...forbidden} />
        </h1>
        <p className="mb-4">
          <FormattedMessage
            id="error.403.registerUsernameDescription"
            defaultMessage="Whoops! Please register a username to view this page."
            description="Error description"
          />
        </p>
        <Button onClick={() => navigate('/register')}>
          <FormattedMessage
            id="error.403.registerUsername"
            defaultMessage="Register Username "
            description="Error action"
          />
        </Button>
      </div>
    </div>
  );
};

export default ForbiddenPage;
