import { transactions } from '@liskhq/lisk-client/browser';
import { DateTime } from 'luxon';
import prettyBytes from 'pretty-bytes';
import getSymbolFromCurrency from 'currency-symbol-map';

export const displayNumber = (input: number | string) => Number(input).toLocaleString();

export const displayBalance = (input: bigint) => {
  const amount = Number(transactions.convertBeddowsToLSK(input.toString()));

  return amount % 1 === 0 ? amount : amount.toFixed(3);
};

export const displayFileSize = (input: number) => prettyBytes(input);

export const beddowsToLsk = (amount: BigInt | string) =>
  transactions.convertBeddowsToLSK(typeof amount === 'string' ? amount : amount.toString());

export const displayDate = (isoDate: string, locale = 'en') =>
  DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY, { locale });

export const displayDateTime = (isoDate: string, locale = 'en') =>
  DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, { locale });

export const formatFiatCurrency = (amount: number, currency: string, locale = 'en') => {
  const isInt = amount % 1 === 0;

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: isInt ? 0 : 2,
    maximumFractionDigits: isInt ? 0 : 2,
  });

  return ` ${getSymbolFromCurrency(currency)} ${formatter.format(typeof amount !== 'number' ? 0 : amount)}`;
};
