import { FormattedMessage, useIntl } from 'react-intl';

import Toggle from 'components/ui/Toggle';
import { Filters } from 'types';

const mimeOptions = [
  {
    type: 'application',
    label: (
      <FormattedMessage
        id="browse.mimeTypeApplication"
        defaultMessage="Application"
        description="MIME type 'Application'"
      />
    ),
  },
  {
    type: 'audio',
    label: <FormattedMessage id="browse.mimeTypeAudio" defaultMessage="Audio" description="MIME type 'Audio'" />,
  },
  {
    type: 'font',
    label: <FormattedMessage id="browse.mimeTypeFont" defaultMessage="Font" description="MIME type 'Font'" />,
  },
  {
    type: 'image',
    label: <FormattedMessage id="browse.mimeTypeImage" defaultMessage="Image" description="MIME type 'Image'" />,
  },
  {
    type: 'model',
    label: <FormattedMessage id="browse.mimeTypeModel" defaultMessage="Model" description="MIME type 'Model'" />,
  },
  {
    type: 'text',
    label: <FormattedMessage id="browse.mimeTypeText" defaultMessage="Text" description="MIME type 'Text'" />,
  },
  {
    type: 'video',
    label: <FormattedMessage id="browse.mimeTypeVideo" defaultMessage="Video" description="MIME type 'Video'" />,
  },
];

const sortOptions = [
  // { type: 'date:asc', label: 'Created Date -  Ascending' },
  {
    type: 'date:asc',
    label: (
      <FormattedMessage
        id="browse.sortByCreatedDateAscending"
        defaultMessage="Created Date - Ascending"
        description="Sorting option for file search"
      />
    ),
  },
  {
    type: 'date:desc',
    label: (
      <FormattedMessage
        id="browse.sortByCreatedDateDescending"
        defaultMessage="Created Date - Descending"
        description="Sorting option for file search"
      />
    ),
  },
  {
    type: 'size:asc',
    label: (
      <FormattedMessage
        id="browse.sortByFileSizeAscending"
        defaultMessage="File Size - Ascending"
        description="Sorting option for file search"
      />
    ),
  },
  {
    type: 'size:desc',
    label: (
      <FormattedMessage
        id="browse.sortByFileSizeDescending"
        defaultMessage="File Size - Descending"
        description="Sorting option for file search"
      />
    ),
  },
];

type Props = { filters: Filters; setFilters: React.Dispatch<React.SetStateAction<Filters>> };

const SearchForm = ({ filters, setFilters }: Props) => {
  const { formatMessage } = useIntl();

  const handleChange = (type: keyof typeof filters, value: string | boolean) => {
    setFilters(prevState => ({ ...prevState, [type]: value }));
  };

  return (
    <div className="flex flex-col gap-4 mb-16">
      <div className="flex">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>

          <input
            type="search"
            value={filters.searchInput}
            onChange={e => handleChange('searchInput', e.target.value)}
            className="base-input pl-10"
            placeholder={formatMessage({
              id: 'browse.searchPlaceholder',
              defaultMessage: 'Search for file or collection name',
              description: 'Placeholder for search input',
            })}
            required
          />
        </div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/2">
          <label className="block">
            <select
              className="base-input"
              value={filters.mimeType}
              onChange={e => handleChange('mimeType', e.target.value)}
            >
              <option value="" className="text-gray-400">
                <FormattedMessage
                  id="browse.allFileTypes"
                  defaultMessage="All file types"
                  description="Filter for file search"
                />
              </option>

              {mimeOptions.map(t => (
                <option value={t.type} key={t.type}>
                  {t.label}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="w-1/2">
          <label className="block">
            <select
              className="base-input"
              value={filters.sortType}
              onChange={e => handleChange('sortType', e.target.value)}
            >
              <option value="" disabled>
                <FormattedMessage id="browse.sortBy" defaultMessage="Sort by" description="Filter for file search" />
              </option>

              {sortOptions.map(option => (
                <option value={option.type} key={option.type}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>

      <div className="flex gap-2">
        <label>
          <Toggle
            isChecked={filters?.isUpdated !== undefined ? filters.isUpdated : false}
            onCheck={() => handleChange('isUpdated', filters.isUpdated !== undefined ? !filters.isUpdated : false)}
          />
        </label>
        <span>
          <FormattedMessage
            id="browse.updatedFiles"
            defaultMessage="Updated files"
            description="Filter for file search"
          />
        </span>
      </div>
    </div>
  );
};

export default SearchForm;
