import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import Modal from 'components/ui/Modal';
import Toggle from 'components/ui/Toggle';
import CustomFields, { CustomField } from 'components/upload/CustomFields';
import useWallet from 'hooks/useWallet';
import { cancel, freelyAccessibleText, insufficientFee, noPassphraseError, privateText, submit } from 'intl/generic';
import { sendUpdateFileAsset } from 'services/transactions';
import { DamFile, UpdateFileAssetProps } from 'types';
import { optimisticallyUpdateFile } from 'utils/cache';
import { handleError } from 'utils/errors';
import { bufferToJson, fileIsTimedTransfer, getTransactionTimestamp, jsonToBuffer } from 'utils/helpers';

type Props = {
  file: DamFile;
};

const UpdateFile = ({ file }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [transferFee, setTransferFee] = useState(file.data.transferFee);
  const [accessPermissionFee, setAccessPermissionFee] = useState(file.data.accessPermissionFee);
  const [isPrivate, setIsPrivate] = useState(file.data.private);
  const [downloadPermissionLevel, setDownloadPermissionLevel] = useState(file.data.downloadPermissionLevel);
  const [customFields, setCustomFields] = useState<CustomField[]>(bufferToJson(file.data.customFields));

  const { formatMessage } = useIntl();
  const { wallet } = useWallet();
  const queryClient = useQueryClient();

  const handleDownloadPermissionLevelChange = () => {
    if (downloadPermissionLevel === 'standard') {
      setDownloadPermissionLevel('public');
      setIsPrivate(false);
    } else {
      setDownloadPermissionLevel('standard');
    }
  };

  const updateFileMutation = useMutation({
    mutationFn: ({ passphrase, txAsset }: { passphrase: string; txAsset: UpdateFileAssetProps }) =>
      sendUpdateFileAsset(passphrase, txAsset),
    onSuccess: (_, { txAsset }) => {
      optimisticallyUpdateFile(queryClient, txAsset, isPrivate, customFields);
      toast.success(
        formatMessage({
          id: 'view.updateFileModal.fileUpdated',
          defaultMessage: 'File updated!',
          description: 'Notification',
        }),
      );
    },
    onError: handleError,
    onSettled: () => setModalIsOpen(false),
  });

  const handleUpdateFile = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!wallet?.passphrase) {
      toast.error(formatMessage(noPassphraseError));
      return;
    }

    if (transferFee < 1 || accessPermissionFee < 1) {
      toast.error(formatMessage(insufficientFee));
      return;
    }

    const txAsset: UpdateFileAssetProps = {
      fileId: file.data.id,
      transferFee,
      accessPermissionFee,
      private: isPrivate,
      customFields: jsonToBuffer(customFields),
      timestamp: getTransactionTimestamp(),
      downloadPermissionLevel,
    };

    updateFileMutation.mutate({ passphrase: wallet.passphrase, txAsset });
  };

  return (
    <>
      <div className="text-gray-400 cursor-pointer" onClick={() => setModalIsOpen(true)}>
        <Icon type="faEdit" />
      </div>

      <Modal
        title={formatMessage({
          id: 'view.updateFileModal.updateFile',
          defaultMessage: 'Update File',
          description: 'Modal title',
        })}
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
      >
        <form onSubmit={handleUpdateFile}>
          <div className="flex gap-8 justify-center mb-8">
            <div className="w-full max-w-md flex flex-col gap-4">
              <label className="block ">
                <span className="label">
                  <FormattedMessage
                    id="view.updateFileModal.newTransferFee"
                    defaultMessage="New Transfer Fee"
                    description="Label for input"
                  />
                </span>
                <div>
                  <input
                    min={1}
                    id="transfer-input"
                    type="number"
                    value={transferFee}
                    onChange={e => setTransferFee(Number(e.target.value))}
                    className="base-input mb-1 block"
                  />
                </div>
              </label>

              <label className="block">
                <span className="label">
                  <FormattedMessage
                    id="view.updateFileModal.newAccessPermissionFee"
                    defaultMessage="New Access Permission Fee"
                    description="Label for input"
                  />
                </span>
                <div>
                  <input
                    min={1}
                    id="access-input"
                    type="number"
                    value={accessPermissionFee}
                    onChange={e => setAccessPermissionFee(Number(e.target.value))}
                    className="base-input mb-1 block"
                    disabled={fileIsTimedTransfer(file)}
                  />
                </div>
              </label>

              <div className="grid grid-cols-2">
                <label className="">
                  <span className="label">
                    <FormattedMessage {...privateText} />
                  </span>
                  <div className="mt-1">
                    <Toggle
                      isChecked={isPrivate}
                      onCheck={() => setIsPrivate(!isPrivate)}
                      disabled={downloadPermissionLevel === 'public'}
                    />
                  </div>
                </label>

                <label className="">
                  <span className="label">
                    <FormattedMessage {...freelyAccessibleText} />
                  </span>
                  <div className="mt-1">
                    <Toggle
                      isChecked={downloadPermissionLevel === 'public'}
                      onCheck={handleDownloadPermissionLevelChange}
                    />
                  </div>
                </label>
              </div>

              <CustomFields customFields={customFields} setCustomFields={setCustomFields} isUpdate />
            </div>
          </div>

          <div className="flex justify-center gap-8">
            <Button type="button" color="primary-bordered" onClick={() => setModalIsOpen(false)}>
              <FormattedMessage {...cancel} />
            </Button>

            <Button type="submit">
              <FormattedMessage {...submit} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UpdateFile;
