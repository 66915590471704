import React, { useState } from 'react';
import toast from 'react-hot-toast';

import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import Label from 'components/ui/Label';
import * as storage from 'utils/storage';

import SaveTemplateModal from './SaveTemplateModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { customFieldsText, template } from 'intl/generic';

export type CustomField = {
  name: string;
  value: string;
};

type Template = {
  id: string;
  customFields: CustomField[];
};

type Props = {
  customFields: CustomField[];
  setCustomFields: React.Dispatch<React.SetStateAction<CustomField[]>>;
  isUpdate?: boolean;
};

const CustomFields = ({ customFields, setCustomFields, isUpdate }: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState(
    storage.getFromLocalStorage<Template[]>('templates') || ([] as Template[]),
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  const updateCustomField = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const updatedFields = [...customFields];
    updatedFields[i][e.target.name as 'name' | 'value'] = e.target.value;
    setCustomFields(updatedFields);
  };

  const removeCustomField = (index: number) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  const handleTemplateChange = (template: string) => {
    setSelectedTemplate(template);
    setCustomFields(templates.find(t => t.id === template)?.customFields || []);

    if (template) {
      toast.success(
        <FormattedMessage
          id="input.customFields.switchedTemplate"
          defaultMessage="Switched template to {template}!"
          values={{ template: template }}
          description="Switch template text"
        />,
      );
    }
  };

  const handleAddField = () => {
    setCustomFields([...customFields, { name: '', value: '' }]);
  };

  const onSaveTemplate = () => {
    if (selectedTemplate) {
      handleSaveTemplate(selectedTemplate);
      return;
    }

    setModalIsOpen(true);
  };

  const handleSaveTemplate = (name: string) => {
    const updatedTemplates = [...templates];

    const existingTemplate = updatedTemplates.find(t => t.id === selectedTemplate);

    if (existingTemplate) {
      existingTemplate.customFields = customFields;
    } else {
      updatedTemplates.push({ id: name, customFields });
    }

    storage.setToLocalStorage(updatedTemplates, 'templates');
    setTemplates(updatedTemplates);

    toast.success(
      <FormattedMessage
        id="input.customFields.templateSaved"
        defaultMessage="Template saved!"
        description="Save template text"
      />,
    );

    setSelectedTemplate(name);
    setCustomFields(customFields);
  };

  const handleDeleteTemplate = () => {
    const updatedTemplates = templates.filter(t => t.id !== selectedTemplate);
    setTemplates(updatedTemplates);
    resetCustomFields();
    storage.setToLocalStorage(updatedTemplates, 'templates');
    toast.success(
      <FormattedMessage
        id="input.customFields.templateDeleted"
        defaultMessage="Template deleted!"
        description="Delete template text"
      />,
    );
  };

  const resetCustomFields = () => {
    setSelectedTemplate('');
    setCustomFields([]);
  };

  return (
    <>
      {!isUpdate && (
        <label className="block">
          <Label text={formatMessage(template)} />
          <select className="base-input" value={selectedTemplate} onChange={e => handleTemplateChange(e.target.value)}>
            <option value="">
              {formatMessage({
                id: 'input.customFields.newTemplate',
                defaultMessage: 'New Template',
                description: 'New Template text',
              })}
            </option>

            {templates.map(t => (
              <option value={t.id} key={t.id}>
                {t.id}
              </option>
            ))}
          </select>
        </label>
      )}

      <div>
        {customFields.length > 0 && <Label text={formatMessage(customFieldsText)} />}
        {customFields.map((field, i) => (
          <div key={`custom-field-${i}`} className="flex gap-4 mb-2">
            <input
              placeholder={formatMessage({
                id: 'input.customFields.fieldName',
                defaultMessage: 'Field name',
                description: 'Input field text',
              })}
              value={customFields[i].name}
              onChange={e => updateCustomField(e, i)}
              type="text"
              className="base-input"
              name="name"
            />
            <input
              placeholder={formatMessage({
                id: 'input.customFields.fieldValue',
                defaultMessage: 'Field value',
                description: 'Input field text',
              })}
              value={customFields[i].value}
              onChange={e => updateCustomField(e, i)}
              type="text"
              className="base-input"
              name="value"
            />

            <button onClick={() => removeCustomField(i)}>
              <Icon type="faTrash" className="mt-1 text-lg" />
            </button>
          </div>
        ))}
      </div>

      {!isUpdate && (
        <>
          <div className="flex justify-center gap-2 md:gap-8">
            <Button color="primary-bordered" onClick={handleAddField} type="button">
              <FormattedMessage
                id="input.customFields.addField"
                defaultMessage="Add Fields"
                description="Button text"
              />
            </Button>
            <Button color="primary-bordered" onClick={onSaveTemplate} type="button">
              <FormattedMessage
                id="input.customFields.saveTemplate"
                defaultMessage="Save Template"
                description="Button text"
              />
            </Button>
            <Button color="primary-bordered" onClick={handleDeleteTemplate} type="button" disabled={!selectedTemplate}>
              <FormattedMessage
                id="input.customFields.deleteTemplate"
                defaultMessage="Delete Template"
                description="Button text"
              />
            </Button>
          </div>

          <SaveTemplateModal
            modalIsOpen={modalIsOpen}
            handleClose={() => setModalIsOpen(false)}
            handleConfirm={handleSaveTemplate}
            selectedTemplate={selectedTemplate}
          />
        </>
      )}
    </>
  );
};

export default CustomFields;
