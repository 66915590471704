import { useIntl } from 'react-intl';

import SEO from 'components/ui/SEO';
import Welcome from 'components/welcome';
import { welcome } from 'intl/generic';

const WelcomePage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage(welcome);

  return (
    <section className="max-w-xl w-full">
      <SEO pageTitle={title} />
      <Welcome />
    </section>
  );
};

export default WelcomePage;
