import { Locale } from 'intl/Intl';
import { useCustomizationStore } from 'stores/useCustomizationStore';
import { GB, IT } from 'country-flag-icons/react/3x2';
import { ReactElement, useState } from 'react';
import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';
import { cancel } from 'intl/generic';
import { FormattedMessage } from 'react-intl';

const localeOptions: { [key in Locale]: { locale: Locale; flag: ReactElement } } = {
  en: {
    locale: 'en',
    flag: <GB />,
  },
  it: {
    locale: 'it',
    flag: <IT />,
  },
};

export default function LocaleSwitcher() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { locale, updateLocale } = useCustomizationStore();

  const handleUpdateLocale = (locale: Locale) => {
    setModalIsOpen(false);
    updateLocale(locale);
  };

  return (
    <>
      <div className="w-8 shadow-sm cursor-pointer" onClick={() => setModalIsOpen(true)}>
        {localeOptions[locale]?.flag ?? <GB />}
      </div>

      <Modal title="Change Locale" isOpen={modalIsOpen} handleClose={() => setModalIsOpen(false)}>
        <div className="text-center mb-8">
          <div className="flex justify-center gap-8">
            {Object.entries(localeOptions).map(([key, value]) => (
              <span
                className="w-24 shadow-md cursor-pointer "
                onClick={() => handleUpdateLocale(value.locale)}
                key={value.locale}
              >
                {value.flag}
              </span>
            ))}
          </div>
        </div>

        <div className="flex justify-center gap-4">
          <Button type="button" color="primary-bordered" onClick={() => setModalIsOpen(false)}>
            {<FormattedMessage {...cancel} />}
          </Button>
        </div>
      </Modal>
    </>
  );
}
