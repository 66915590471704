import { ReactElement } from 'react';

type Props = {
  text: string | ReactElement;
};

const PageTitle = ({ text }: Props) => {
  return <h1 className="text-center text-secondary-700 mt-0 mb-16 uppercase">{text}</h1>;
};

export default PageTitle;
