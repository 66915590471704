import config from 'config';
import { Helmet } from 'react-helmet-async';

const DESCRIPTION_FALLBACK = 'You share, we lock!';

const getTitle = (pageTitle?: string) => (pageTitle ? `${pageTitle} | ${config.PROJECT_TITLE}` : config.PROJECT_TITLE);
const getDescription = (pageDescription?: string) => pageDescription ?? DESCRIPTION_FALLBACK;
const creator = `@${config.PROJECT_TITLE}`;

type Props = { pageTitle?: string; pageDescription?: string; rootMetadata?: boolean };

const SEO = ({ pageTitle, pageDescription, rootMetadata }: Props) => {
  const title = getTitle(pageTitle);
  const description = getDescription(pageDescription);

  /** Only update relevant metadata */
  if (!rootMetadata) {
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={creator} />
        <meta name="twitter:creator" content={creator} />
        <meta name="twitter:description" content={description} />

        <meta property="og:url" content="https://app.stellab.it" />
        <meta property="og:site_name" content={config.PROJECT_TITLE} />
        <meta property="og:title" content={config.PROJECT_TITLE} />
        <meta property="og:description" content={description} />
      </Helmet>
    );
  }

  /** Set all metadata */
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={creator} />
      <meta name="twitter:creator" content={creator} />
      <meta name="twitter:description" content={description} />
      <meta property="og:url" content="https://app.stellab.it" />
      <meta property="og:site_name" content={config.PROJECT_TITLE} />
      <meta property="og:title" content={config.PROJECT_TITLE} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content="https://" /> */}{' '}
    </Helmet>
  );
};

export default SEO;
